<template>
  <v-container>
    <v-row>
      <v-col
        v-if="showViewAsCustomer"
        class="mt-5 ml-2"
        cols="4"
      >
        <ViewAsCustomer />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-card-title>
            Suppression Code
          </v-card-title>
          <v-col
            cols="12"
            sm="6"
          >
            <v-form v-model="valid">
              <v-text-field
                v-model="suppressionCode"
                :rules="[rules.required, rules.counter, rules.special]"
                class="mb-3"
                counter="30"
                maxlength="30"
                dense
                label="Suppression Code"
                hide-details="auto"
                @change="clearErrors()"
              />
              <v-col>
                <small
                  v-if="addError !== ''"
                  class="mb-5"
                  style="color: red"
                >
                  {{ addError }}
                </small>
              </v-col>
              <v-col>
                <v-btn
                  color="primary"
                  :disabled="!valid"
                  @click="validateAddEmailSuppression('a')"
                >
                  add
                </v-btn>
              </v-col>
            </v-form>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ml-2">
          <v-row no-gutters>
            <v-col
              cols="12"
              sm="6"
              md="7"
            >
              <v-card-title class="ml-2">
                Search Results
              </v-card-title>
            </v-col>
            <v-col
              cols="3"
              class="pr-6"
              md="3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-col>
            <v-col
              cols="6"
              md="2"
            >
              <v-btn
                class="mt-3 ml-4"
                color="primary"
                :disabled="selected.length === 0"
                @click="updateEmailSuppression('u')"
              >
                Update
              </v-btn>
            </v-col>
          </v-row>
          <v-data-table
            v-model="selected"
            item-key="suppressionCode"
            class="mt-10"
            :headers="suppressionHeaders"
            :items="results"
            :loading="resultsLoading"
            :search="search"
            single-select
            show-select
            checkbox-color="blue"
          >
            <template v-slot:[`item.data-table-select`]="{isSelected,select}">
              <v-simple-checkbox
                v-ripple
                color="primary"
                :value="isSelected"
                @input="select($event)"
              />
            </template>
            <template v-slot:[`item.suppressionCode`]="{ item }">
              <div>
                {{ item.suppressionCode }}
              </div>
            </template>
            <template v-slot:[`item.createDate`]="{ item }">
              <div>
                <span>{{ item.createDate | displayDateFormat }}</span>
              </div>
            </template>
            <template v-slot:[`item.emailCount`]="{ item }">
              <div>
                <span>{{ item.emailCount }}</span>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { nowwService } from '@/shared/services'
import claims from '@/shared/models/auth/claims'
import { mapGetters } from 'vuex'
import mutationTypes from '@/store/mutation-types'

export default {
  name: 'EmailSuppression',

  components: {
    ViewAsCustomer: () =>
      import('@/views/pages/components/user-state/ViewAsCustomer')
  },

  data () {
    return {
      // rules: [v => v.length <= 30 || 'Max 30 characters'],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 3 || 'Min 3 characters',
        special: value => {
          const pattern = /^[a-zA-Z0-9 _-]*$/
          return pattern.test(value) || 'No special characters allowed.'
        }
      },
      suppressionCode: '',
      selected: [],
      addError: '',
      isSelected: false,
      selectedItemCode: '',
      selectedID: '',
      valid: false,
      unsubscribe: null,
      search: '',
      results: [],
      addResults: '',
      resultsLoading: false,
      suppressionHeaders: [
        {
          text: 'Suppression Code',
          align: 'start',
          sortable: false,
          class: 'title',
          value: 'suppressionCode'
        },
        { text: 'Create Date', value: 'createDate', sortable: false, class: 'title' },
        { text: '# Of Emails', value: 'emailCount', sortable: false, class: 'title', align: 'center' }
      ]
    }
  },

  computed: {
    ...mapGetters(['simulatedCustomerCrmId']),
    ...mapGetters('auth', [
      'isMMSRole',
      'getTenantId',
      'getTenantCrmId',
      'hasClaimKV'
    ]),
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    }
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.loadEmailSuppression()
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  created () {
    this.loadEmailSuppression()
  },

  methods: {
    clearErrors () {
      this.addError = ''
    },
    async loadEmailSuppression () {
      var _this = this
      _this.resultsLoading = true
      await nowwService.getEmailSuppression(_this.$store.getters['simulatedCustomerNowwId']())
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.results = resp
            _this.resultsLoading = false
          }
        })
    },
    async validateAddEmailSuppression (value) {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.supressionCode = value === 'u' ? _this.selectedItemCode : _this.suppressionCode
      _this.resultsLoading = true
      await nowwService.emailSuppressionCodeCount({
        customerId: customerId,
        supressionCode: _this.supressionCode
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else if (resp !== 0) {
            _this.addError = 'There is already a suppression file with the code you entered.'
            _this.resultsLoading = false
          } else {
            _this.codeCountResults = resp
            _this.resultsLoading = false
            _this.addEmailSuppression(value)
          }
        })
    },
    async addEmailSuppression (value) {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      _this.supressionCode = value === 'u' ? _this.selectedItemCode : _this.suppressionCode
      _this.resultsLoading = true
      await nowwService.addEmailSuppression({
        customerId: customerId,
        supressionCode: _this.supressionCode
      })
        .then(function (resp) {
          if (resp.errorResponse) {
            _this.$store.commit('setGlobalSnackbar', {
              message: resp.errorResponse,
              color: 'error'
            })
          } else {
            _this.addResults = resp
            _this.resultsLoading = false
            _this.$router.push({ path: '/emailSuppressionImport',
              query: {
                cid: customerId,
                sc: _this.supressionCode,
                sid: resp }
            })
          }
        })
    },
    updateEmailSuppression (value) {
      var _this = this
      var customerId = _this.$store.getters['simulatedCustomerNowwId']()
      for (var i = 0; _this.selected.length; i++) {
        if (_this.selected[i].suppressionCode !== undefined) {
          _this.selectedItemCode = _this.selected[i].suppressionCode
          _this.selectedId = _this.selected[i].id
          break
        }
      }
      _this.supressionCode = value === 'u' ? _this.selectedItemCode : _this.supressionCode
      _this.resultsLoading = true

      _this.$router.push({ path: '/emailSuppressionImport',
        query: {
          cid: customerId,
          sc: _this.supressionCode,
          sid: _this.selectedId,
          edit: 'Y'
        }
      })
    }
  }
}
</script>
